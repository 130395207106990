<template>
    <div class="configuracoes-imobiliaria-component">
        <section class="cadastro-configuracoes">
            <div class="container">
                <div class="header-property">
                    <h2>Editar Configurações</h2>
                    <div class="close-property" @click="closeForm">X</div>
                </div>
                <hr>
                <form id="form-configuracoes" @submit.prevent="showSaveConfirmation">
                    <!-- Configurações da Imobiliária -->
                    <div class="section">
                        <h3>Informações da Imobiliária</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Nome*</h4>
                                <input type="text" placeholder="Digite o nome da sua Imobiliária" v-model="form.nome"
                                    maxlength="50" required tabindex="1" />
                            </div>
                            <div class="group-input">
                                <h4>Slogan</h4>
                                <input type="text" placeholder="Digite seu slogan" v-model="form.slogan" maxlength="50"
                                    tabindex="2"></input>
                            </div>
                            <div class="group-input">
                                <h4>CRECI*</h4>
                                <input type="text" v-model="form.creci" maxlength="20" required tabindex="3" />
                            </div>
                        </div>
                        <!-- <div class="grid-container">
   
                        </div> -->
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>CEP*</h4>
                                <input type="text" v-model="form.endereco.cep"
                                    @input="form.endereco.cep = formatarCEP($event.target.value), inserirEnderecoBuscado($event.target.value)"
                                    required tabindex="4" />
                            </div>
                            <div class="group-input">
                                <h4>Rua*</h4>
                                <input type="text" v-model="form.endereco.rua" required tabindex="5" />
                            </div>
                            <div class="group-input">
                                <h4>Número*</h4>
                                <input type="number" v-model="form.endereco.numero" required tabindex="6" />
                            </div>
                            <div class="group-input">
                                <h4>Complemento</h4>
                                <input type="text" v-model="form.endereco.complemento" tabindex="7" />
                            </div>
                            <div class="group-input">
                                <h4>Bairro*</h4>
                                <input type="text" v-model="form.endereco.bairro" required tabindex="8" />
                            </div>
                            <div class="group-input">
                                <h4>Cidade*</h4>
                                <input type="text" v-model="form.endereco.cidade" required tabindex="9" />
                            </div>
                            <div class="group-input">
                                <h4>Estado*</h4>
                                <input type="text" v-model="form.endereco.estado" required tabindex="10" />
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <h3>Contato</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Telefone WhatsApp*</h4>
                                <input type="text" v-model="form.telefoneWhatsApp" required
                                    @input="form.telefoneWhatsApp = formatarTelefone($event.target.value)"
                                    tabindex="12" />
                            </div>
                            <div class="group-input">
                                <h4>Telefone Residencial</h4>
                                <input type="text" v-model="form.telefoneResidencial" required
                                    @input="form.telefoneResidencial = formatarTelefone($event.target.value)"
                                    tabindex="11" />
                            </div>
                            <div class="group-input">
                                <h4>Email*</h4>
                                <input type="email" v-model="form.email" required tabindex="13" />
                            </div>
                        </div>
                    </div>
                    <!-- Sobre a Imobiliária -->
                    <div class="section">
                        <h3>Sobre a Imobiliária*</h3>
                        <!-- <h4>Telefone Residencial</h4> -->
                        <div class="group-input">
                            <textarea v-model="form.sobre" required tabindex="14"></textarea>
                        </div>
                        <hr>
                    </div>

                    <!-- Cores -->
                    <div class="section">
                        <h3>Cores</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <div class="input-title">
                                    <h4>Cor Primária</h4>
                                    <span class="sub-text">(Cor para os botões do site)</span>
                                </div>
                                <input type="color" v-model="form.cor" tabindex="15" />
                            </div>
                            <div class="group-input">
                                <div class="input-title">
                                    <h4>Cor Secundária</h4>
                                    <span class="sub-text">(Cor ao passar o mouse sobre itens do site)</span>
                                </div>
                                <input type="color" v-model="form.corHover" tabindex="16" />
                            </div>
                        </div>
                        <hr>
                    </div>

                    <!-- Imagens -->
                    <div class="section">
                        <h3>Imagens</h3>
                        <div class="grid-container grid-imagens">
                            <div class="group-input">
                                <div class="upload-images-container">
                                    <h4>Upload Logo</h4>
                                    <input type="file" accept="image/*" @change="uploadLogo" ref="logoInput"
                                        tabindex="17" style="display: none;" />
                                    <div v-if="logoPreview">
                                        <img :src="logoPreview" alt="Logo Preview" />
                                        <p>{{ form.logo?.name || form.logo?.filename }}</p>
                                    </div>
                                    <button type="button" @click="triggerFileInput('logoInput')">Escolher
                                        imagem</button>
                                </div>
                            </div>

                            <div class="group-input">
                                <div class="upload-images-container">
                                    <h4>Upload Logo Rodapé</h4>
                                    <input type="file" accept="image/*" @change="uploadLogoRodape" ref="logoRodapeInput"
                                        tabindex="18" style="display: none;" />
                                    <div v-if="logoRodapePreview">
                                        <img :src="logoRodapePreview" alt="Logo Rodapé Preview" />
                                        <p>{{ form.logoRodape?.name || form.logoRodape?.filename }}</p>
                                    </div>
                                    <button type="button" @click="triggerFileInput('logoRodapeInput')">Escolher
                                        imagem</button>
                                </div>
                            </div>

                            <div class="group-input">
                                <div class="upload-images-container">
                                    <h4>Upload Marca D'agua</h4>
                                    <input type="file" accept="image/*" @change="uploadMarcaDagua" ref="marcaDaguaInput"
                                        tabindex="20" style="display: none;" />
                                    <div v-if="marcaDaguaPreview">
                                        <img :src="marcaDaguaPreview" alt="Marca D'agua Preview">
                                        <p>{{ form.marcaDagua?.name || form.marcaDagua?.filename }}</p>
                                    </div>
                                    <button type="button" @click="triggerFileInput('marcaDaguaInput')">Escolher
                                        imagem</button>
                                </div>
                            </div>
                        </div>

                        <div class="grid-container grid-imagens fundo-transicao">
                            <div class="group-input imagens-fundo">
                                <div class="upload-images-container">
                                    <div class="input-title">
                                        <h4>Upload imagens de fundo</h4>
                                        <span class="sub-text">({{ form.imagensFundo.length }} de 3 imagens)</span>
                                    </div>
                                    <div ref="previewFundo" class="preview-container">
                                        <div class="preview-container">
                                            <div ref="uploadPlaceholderFundo" class="image-upload upload-placeholder"
                                                v-show="form.imagensFundo.length < 3">
                                                <label for="imagemFundoInput">
                                                    <div class="upload-placeholder">
                                                        <span>+</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="file" id="imagemFundoInput" accept="image/*" multiple
                                        style="display: none" @change="carregarImagensSelecionadasFundo">
                                    <div class="btn-modal">
                                    </div>
                                </div>
                            </div>

                            <div class="group-input campo-transicao">
                                <div>
                                    <div class="input-title">
                                        <h4>Tempo de transição das imagens</h4>
                                        <span class="sub-text">(Em segundos)</span>
                                    </div>
                                    <input type="number" v-model="form.tempoTransicaoImagens" min="1" max="60"
                                        placeholder="Ex: 5 segundos" />
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <!-- Google Tag Manager -->
                    <div class="section">
                        <h3>Google Tag Manager</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>ID do Google Tag Manager</h4>
                                <input type="text" v-model="form.googleTagManagerID" placeholder="Ex: GTM-XXXXXXX"
                                    tabindex="21" />
                            </div>
                            <div class="group-input">
                                <div class="input-title">
                                    <h4>Palavras-chave</h4>
                                    <span class="sub-text">(Separar por vírgulas)</span>
                                </div>
                                <textarea v-model="form.palavrasChave" placeholder="Digite as palavras-chave"
                                    tabindex="22"></textarea>
                            </div>
                            <div class="group-input">
                                <div class="input-title">
                                    <h4>Descrição</h4>
                                    <span class="sub-text">(Descrição resumida do site)</span>
                                </div>
                                <textarea v-model="form.descricao" placeholder="Digite a descrição"
                                    tabindex="23"></textarea>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <!-- Configurações de Exibição -->
                    <div class="section">
                        <h3>Configurações de Exibição</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Exibir Seção de Categorias</h4>
                                <label class="switch">
                                    <input type="checkbox" :checked="form.exibirCategorias"
                                        @change="toggleExibirCategorias($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <!-- Links Sociais -->
                    <div class="section">
                        <h3>Links Sociais</h3>
                        <div class="grid-container">
                            <div class="group-input">
                                <h4>Link Instagram</h4>
                                <input type="text" v-model="form.linkInstagram" tabindex="19" />
                            </div>
                            <div class="group-input">
                                <h4>Link Facebook</h4>
                                <input type="text" v-model="form.linkFacebook" tabindex="20" />
                            </div>
                        </div>
                        <hr>
                    </div>

                    <!-- Botões -->
                    <div class="btn-container">
                        <div class="btn-group-right">
                            <button type="button" class="button" @click="closeForm">Cancelar</button>
                            <button type="submit" class="btn-salvar-form" :disabled="!isFormValid">Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <Popup ref="popup" />
        <Loading :loading="isLoading" />
    </div>
</template>

<script>

import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import Loading from '@/mixins/Loading.vue';
import { emitter } from '@/router/admin.js';
import script from '@/mixins/script.js';
import Sortable from 'sortablejs';

export default {
    name: 'ConfiguracoesImobiliaria',
    mixins: [script],
    data() {
        return {
            form: {
                nome: '',
                slogan: '',
                creci: '',
                endereco: {
                    cep: '',
                    rua: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                },
                telefoneWhatsApp: '',
                telefoneResidencial: '',
                email: '',
                sobre: '',
                cor: '',
                corHover: '',
                logo: null,
                logoRodape: null,
                marcaDagua: null,
                imagensFundo: [],
                tempoTransicaoImagens: '',
                googleTagManagerID: '',
                palavrasChave: '',
                descricao: '',
                exibirCategorias: false,
                linkInstagram: '',
                linkFacebook: ''
            },
            logoPreview: null,
            logoRodapePreview: null,
            imagemFundoPreviews: [],
            imagensRemovidas: [],
            marcaDaguaPreview: null,
            isFormValid: false,
            isLoading: false,
            inicialLogo: null,
            inicialLogoRodape: null,
            inicialImagemFundo: null,
            inicialMarcaDagua: null
        };
    },
    computed: {
        isFormValid() {
            return this.form.nome && this.form.creci && this.form.endereco.cep && this.form.email && this.form.sobre;
        }
    },
    async created() {
        await this.carregarConfiguracoes();
    },
    methods: {
        closeForm() {
            if (this.hasFormChanged()) {
                this.$refs.popup.showConfirm('Deseja sair sem salvar as informações?', (confirmed) => {
                    if (confirmed) {
                        this.$router.push('/admin/dashboard');
                    }
                });
            } else {
                this.$router.push('/admin/dashboard');
            }
        },
        showSaveConfirmation() {
            this.$refs.popup.showConfirm('Deseja salvar as configurações?', (confirmed) => {
                if (confirmed) {
                    this.handleSubmit();
                }
            });
        },
        saveInitialFormState() {
            this.initialFormState = JSON.parse(JSON.stringify(this.form));
            this.inicialLogo = this.form.logo;
            this.inicialLogoRodape = this.form.logoRodape;
            this.inicialImagemFundo = this.form.imagemFundo;
            this.inicialMarcaDagua = this.form.marcaDagua;
        },
        hasFormChanged() {
            const formChanged = JSON.stringify(this.form) !== JSON.stringify(this.initialFormState);
            return formChanged;
        },
        uploadLogo(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.logo = file;
                this.logoPreview = URL.createObjectURL(file);
            }
        },
        uploadLogoRodape(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.logoRodape = file;
                this.logoRodapePreview = URL.createObjectURL(file);
            }
        },
        uploadImagemFundo(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.imagemFundo = file;
                this.imagemFundoPreview = URL.createObjectURL(file);
            }
        },
        uploadMarcaDagua(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.marcaDagua = file;
                this.marcaDaguaPreview = URL.createObjectURL(file);
            }
        },
        triggerFileInput(refName) {
            this.$refs[refName].click();
        },
        async enviarLogo() {
            if (this.inicialLogo !== this.form.logo) {
                console.log(this.form.logo)
                const formData = new FormData();
                formData.append('logo', this.form.logo);
                try {
                    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes/upload/logo`, {
                        method: 'PUT',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }

                    const data = await response.json();
                    console.log('Upload bem-sucedido', data);

                    this.form.logo = data;

                } catch (error) {
                    console.error('Erro ao enviar logo', error);
                    await this.$refs.popup.showAlert('Erro ao enviar logo: ' + error.message);
                }
            }
        },
        async enviarLogoRodape() {
            if (this.inicialLogoRodape !== this.form.logoRodape) {
                const formData = new FormData();
                formData.append('logoRodape', this.form.logoRodape);
                try {
                    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes/upload/logoRodape`, {
                        method: 'PUT',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }

                    const data = await response.json();
                    console.log('Upload bem-sucedido', data);

                    this.form.logoRodape = data;

                } catch (error) {
                    console.error('Erro ao enviar logo rodapé', error);
                    await this.$refs.popup.showAlert('Erro ao enviar logo rodapé: ' + error.message);
                }
            }
        },
        async enviarImagemFundo() {
            this.form.imagensFundo = this.form.imagensFundo || [];
            this.imagensRemovidas = this.imagensRemovidas || [];

            const novasImagens = this.form.imagensFundo.filter(imagem => imagem.isNew);
            console.log('Novas imagens a serem enviadas:', novasImagens);
            console.log('Imagens removidas:', this.imagensRemovidas);

            if (novasImagens.length > 0 || this.imagensRemovidas.length > 0) {
                const formData = new FormData();

                novasImagens.forEach(imagem => {
                    formData.append('imagensFundo', imagem.file);
                });

                if (this.imagensRemovidas.length > 0) {
                    formData.append('imagensRemovidas', JSON.stringify(this.imagensRemovidas.map(img => img._id)));
                    console.log('Enviando imagens removidas para o backend:', this.imagensRemovidas);
                }

                const ordemImagens = this.form.imagensFundo.map((imagem, index) => ({
                    id: imagem._id || null,
                    ordem: index,
                    filename: imagem.filename
                }));
                formData.append('ordemImagens', JSON.stringify(ordemImagens));
                console.log('Ordem das imagens a serem enviadas:', ordemImagens);

                try {
                    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes/upload/imagemFundo`, {
                        method: 'PUT',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }

                    const data = await response.json();
                    console.log('Upload de Imagens de Fundo bem-sucedido', data);

                    this.form.imagensFundo = data.imagensFundo.map((img, index) => ({
                        ...img,
                        ordem: index
                    }));

                    this.imagensRemovidas = [];

                } catch (error) {
                    console.error('Erro ao enviar Imagens de Fundo', error);
                }
            }
        },
        async enviarMarcaDagua() {
            if (this.inicialMarcaDagua !== this.form.marcaDagua) {
                const formData = new FormData();
                formData.append('marcaDagua', this.form.marcaDagua);
                try {
                    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes/upload/marcaDagua`, {
                        method: 'PUT',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }

                    const data = await response.json();
                    console.log('Upload bem-sucedido', data);

                    this.form.marcaDagua = data;

                } catch (error) {
                    console.error('Erro ao enviar marca d`agua', error);
                    await this.$refs.popup.showAlert('Erro ao enviar marca d`agua: ' + error.message);
                }
            }
        },
        async inserirEnderecoBuscado(cep) {
            cep = cep.replace(/\D/g, '');
            if (cep.length === 8) {
                const endereco = await this.buscarEndereco(cep);
                this.form.endereco.rua = endereco.logradouro;
                this.form.endereco.bairro = endereco.bairro;
                this.form.endereco.cidade = endereco.localidade;
                this.form.endereco.estado = endereco.uf;
            }
        },
        async carregarConfiguracoes() {
            try {
                const response = await this.handleFetchWithToken(`${process.env.VUE_APP_BACKEND_URL}/configuracoes`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const configuracoes = response.data;
                this.preencherFormulario(configuracoes);
                this.saveInitialFormState();
                this.carregarImagensFundoExistentes();
            } catch (error) {
                const errorMessage = error.response?.data || 'Erro ao carregar dados de configurações';
                await this.$refs.popup.showAlert(errorMessage);
            }
        },
        preencherFormulario(dados) {
            this.form = {
                nome: dados.nome || '',
                slogan: dados.slogan || '',
                creci: dados.creci || '',
                email: dados.email || '',
                telefoneWhatsApp: this.formatarTelefone(dados.whatsapp) || '',
                telefoneResidencial: this.formatarTelefone(dados.telefone) || '',
                endereco: {
                    cep: this.formatarCEP(dados.endereco.cep) || '',
                    rua: dados.endereco.rua || '',
                    numero: dados.endereco.numero || '',
                    complemento: dados.endereco.complemento || '',
                    bairro: dados.endereco.bairro || '',
                    cidade: dados.endereco.cidade || '',
                    estado: dados.endereco.estado || ''
                },
                sobre: dados.sobre || '',
                cor: dados.cor || '',
                corHover: dados.corHover || '',
                logo: dados.logo || '',
                logoRodape: dados.logoRodape || '',
                imagensFundo: dados.imagensFundo || [],
                marcaDagua: dados.marcaDagua || '',
                tempoTransicaoImagens: dados.tempoTransicaoImagens || '',
                googleTagManagerID: dados.googleTagManagerID || '',
                palavrasChave: dados.palavrasChave || '',
                descricao: dados.descricao || '',
                exibirCategorias: dados.exibirCategorias || false,
                linkInstagram: dados.linkInstagram || '',
                linkFacebook: dados.linkFacebook || ''
            };

            this.logoPreview = dados.logo?.url || null;
            this.logoRodapePreview = dados.logoRodape?.url || null;
            this.imagemFundoPreviews = dados.imagensFundo?.map(imagem => imagem.url) || [];
            this.marcaDaguaPreview = dados.marcaDagua?.url || null;
        },
        async handleSubmit() {
            this.isLoading = true;
            await this.enviarLogo();
            await this.enviarLogoRodape();
            await this.enviarImagemFundo();
            await this.enviarMarcaDagua();

            const payload = { ...this.form };

            payload.imagensFundo = this.form.imagensFundo.map((imagem, index) => ({
                ...imagem,
                ordem: index
            }));

            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }

                const responseData = await response.json();
                this.showSuccessAndRedirect('Configurações atualizadas com sucesso!');
                return responseData;

            } catch (error) {
                await this.$refs.popup.showAlert('Erro ao salvar configurações: ' + error.message);
            } finally {
                this.isLoading = false;
            }
        },
        showSuccessAndRedirect(message) {
            const popup = document.getElementById('custom-popup');
            const popupMessage = document.getElementById('popup-message');
            const popupButtons = document.getElementById('popup-buttons');

            popupMessage.innerText = message;
            popup.style.display = 'flex';
            popupButtons.innerHTML = '';
            popupButtons.style.justifyContent = 'center';

            const okButton = document.createElement('button');
            okButton.innerText = 'OK';
            okButton.id = 'popup-ok';
            okButton.onclick = async () => {
                await this.$refs.popup.fecharPopup();
                this.$router.push('/admin/dashboard');
            };

            popupButtons.appendChild(okButton);
        },
        async handleFetchWithToken(url, options = {}) {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token de autenticação não encontrado.');
            }

            const headers = {
                'Authorization': `Bearer ${token}`,
                ...options.headers,
            };

            try {
                const response = await api.get(url, { ...options, headers });

                if (response.status === 401) {
                    const errorData = await response.data;
                    if (errorData === 'Token expired.') {
                        emitter.emit('token-expired');
                        localStorage.removeItem('token');
                        throw new Error('Token expired.');
                    }
                }

                return response;
            } catch (error) {
                console.error('Erro na chamada da API:', error);
                throw error;
            }
        },
        toggleExibirCategorias(checked) {
            this.form.exibirCategorias = checked;
        },
        uploadImagensFundo(event) {
            const files = event.target.files;
            this.form.imagensFundo = Array.from(files).slice(0, 3);
            this.imagemFundoPreviews = [];
            for (let i = 0; i < this.form.imagensFundo.length; i++) {
                const file = this.form.imagensFundo[i];
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagemFundoPreviews.push(e.target.result);
                };
                reader.readAsDataURL(file);
            }
        },
        carregarImagensSelecionadasFundo(event) {
            const input = event.target;
            const preview = this.$refs.previewFundo;
            const uploadPlaceholder = this.$refs.uploadPlaceholderFundo;
            const currentImages = preview.querySelectorAll('.img-container').length;
            const filesToAdd = input.files.length;

            if (currentImages + filesToAdd > 3) {
                this.$refs.popup.showAlert('Você só pode adicionar até 3 imagens.');
                input.value = '';
                return;
            }

            for (const file of input.files) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const imgContainer = document.createElement('div');
                    imgContainer.classList.add('img-container');
                    const img = document.createElement('img');
                    img.src = event.target.result;
                    img.file = file;
                    imgContainer.appendChild(img);

                    const removeButton = document.createElement('button');
                    removeButton.classList.add('remove-image');

                    removeButton.onclick = () => {
                        console.log('Imagem removida clicada:', img.src);

                        imgContainer.remove();

                        const index = this.form.imagensFundo.findIndex((image) => image.src === img.src);
                        if (index !== -1) {
                            const imagemRemovida = this.form.imagensFundo[index];

                            if (imagemRemovida._id) {
                                this.imagensRemovidas.push(imagemRemovida);
                                console.log('Imagem marcada para remoção:', imagemRemovida);
                            }

                            this.form.imagensFundo.splice(index, 1);
                        }

                        console.log('Imagens restantes:', this.form.imagensFundo);
                        console.log('Imagens removidas:', this.imagensRemovidas);
                    };

                    imgContainer.appendChild(removeButton);
                    preview.insertBefore(imgContainer, uploadPlaceholder);

                    this.form.imagensFundo.push({ src: event.target.result, file, isNew: true });
                };
                reader.readAsDataURL(file);
            }

            input.value = '';
        },
        carregarImagensFundoExistentes() {
            const preview = this.$refs.previewFundo;
            const uploadPlaceholder = this.$refs.uploadPlaceholderFundo;

            while (preview.firstChild) {
                preview.removeChild(preview.firstChild);
            }

            preview.appendChild(uploadPlaceholder);

            this.form.imagensFundo.forEach((imagem) => {
                const imgContainer = document.createElement('div');
                imgContainer.classList.add('img-container');
                const img = document.createElement('img');
                img.src = imagem.url || imagem.src;
                imgContainer.appendChild(img);

                const removeButton = document.createElement('button');
                removeButton.classList.add('remove-image');

                removeButton.onclick = () => {
                    console.log('Imagem removida clicada:', img.src);

                    imgContainer.remove();

                    const index = this.form.imagensFundo.indexOf(imagem);
                    if (index > -1) {
                        const imagemRemovida = this.form.imagensFundo[index];
                        if (imagemRemovida._id) {
                            this.imagensRemovidas.push(imagemRemovida);
                            console.log('Imagem marcada para remoção:', imagemRemovida);
                        }

                        this.form.imagensFundo.splice(index, 1);
                    }

                    console.log('Imagens restantes:', this.form.imagensFundo);
                    console.log('Imagens removidas:', this.imagensRemovidas);
                };

                imgContainer.appendChild(removeButton);
                preview.insertBefore(imgContainer, uploadPlaceholder);
            });
        },
        inicializarSortableFundo() {
            if (this.sortableInstanceFundo) {
                this.sortableInstanceFundo.destroy();
            }
            this.sortableInstanceFundo = new Sortable(this.$refs.previewFundo, {
                animation: 150,
                ghostClass: 'sortable-ghost',
                filter: '#uploadPlaceholderFundo',
                onEnd: (evt) => {
                    const imgContainers = Array.from(this.$refs.previewFundo.querySelectorAll('.img-container'));

                    this.form.imagensFundo = imgContainers.map((container, index) => {
                        const img = container.querySelector('img');
                        const tempImg = this.form.imagensFundo.find(image => image.url === img.src || image.src === img.src);

                        return {
                            ...tempImg,
                            ordem: index
                        };
                    });
                },
            });
        },
        async enviarImagensRemovidas() {
            const imagensRemovidas = this.initialFormState.imagensFundo.filter(imagem => !this.form.imagensFundo.includes(imagem));

            if (imagensRemovidas.length > 0) {
                try {
                    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/configuracoes/remove/imagensFundo`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(imagensRemovidas.map(img => img.id)),
                    });

                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }

                    console.log('Imagens removidas com sucesso');

                } catch (error) {
                    console.error('Erro ao remover imagens', error);
                    await this.$refs.popup.showAlert('Erro ao remover imagens: ' + error.message);
                }
            }
        }
    },
    components: {
        Popup,
        Loading
    },
    mounted() {
        this.carregarConfiguracoes();
        this.carregarImagensFundoExistentes();
        this.inicializarSortableFundo();
    }
};
</script>

<style scoped>
@import '@/01-admin/assets/css/configuracoes.css';
</style>
<template>
  <section class="search-home" id="search-section">
    <img class="search__img-home" v-for="(img, index) in configuracoes.imagensFundo" :src="img.url"
      :alt="'Imagem ' + (index + 1)" v-show="index === currentImage" />
    <form class="search__form-home" @submit.prevent="buscarImoveisHome">
      <div class="form__tab_container-home">
        <button type="button" id="comprar-select" class="form__tab-button-home active" @click="toggleTab('comprar')"
          value="Venda">Comprar</button>
        <button type="button" id="alugar-select" class="form__tab-button-home" @click="toggleTab('alugar')"
          value="Aluguel">Alugar</button>
      </div>

      <div class="form__select-home" @click="toggleDisplayTiposImoveis">
        <span id="selected-imovel" value="">Todos imóveis</span><span class="icon-home"></span>

        <ul id="drop-imovel-home" class="suggestions-home" :class="{ 'show': displayTiposImoveis }">
          <li class="selectable-option-home" @click="selectImovel('Todos imóveis')">Todos imóveis</li>
          <li class="selectable-option-home" @click="selectImovel('Casa')">Casa</li>
          <li class="selectable-option-home" @click="selectImovel('Apartamento')">Apartamento</li>
          <li class="selectable-option-home" @click="selectImovel('Kitnet')">Kitnet</li>
          <li class="selectable-option-home" @click="selectImovel('Terreno')">Terreno</li>
          <li class="selectable-option-home" @click="selectImovel('Sítio/Chácara')">Sítio/Chácara</li>
          <li class="selectable-option-home" @click="selectImovel('Comercial')">Comercial</li>
        </ul>
      </div>

      <div class="input-container-home">
        <input type="text" class="form__input-home" name="cidade" placeholder="Cidade" id="cidade_input"
          @click="searchCidades" @input="searchCidades" autocomplete="off" />
        <button type="button" class="clear-btn-home" @click="clearInput('cidade_input')">&times;</button>
        <div id="cidadeSuggestions-home" class="suggestions-home" :class="{ 'show': cidadeSuggestions.length > 0 }">
          <div v-for="cidade in cidadeSuggestions" :key="cidade" class="selectable-option-home"
            @click="selectSuggestionHome(cidade, 'cidade_input')">{{ cidade }}</div>
        </div>
      </div>

      <div class="input-container-home">
        <input type="text" class="form__input-home" name="bairro" placeholder="Bairro" id="bairro_input"
          @click="searchBairros" @input="searchBairros" autocomplete="off" />
        <button type="button" class="clear-btn-home" @click="clearInput('bairro_input')">&times;</button>
        <div id="bairroSuggestions-home" class="suggestions-home" :class="{ 'show': bairroSuggestions.length > 0 }">
          <div v-for="bairro in bairroSuggestions" :key="bairro" class="selectable-option-home"
            @click="selectSuggestionHome(bairro, 'bairro_input')">{{ bairro }}</div>
        </div>
      </div>

      <div class="input-container-home" id="id-imovel-container">
        <input type="text" class="form__input-home" name="id_imovel" placeholder="ID do imóvel" id="id_imovel_input"
          @click="searchIdImovel" @input="searchIdImovel" autocomplete="off" />
        <button type="button" class="clear-btn-home" @click="clearInput('id_imovel_input')">&times;</button>
        <div id="idImovelSuggestions-home" class="suggestions-home" :class="{ 'show': idImovelSuggestions.length > 0 }">
          <div v-for="id_imovel in idImovelSuggestions" :key="id_imovel" class="selectable-option-home"
            @click="selectIdImovelSuggestion(id_imovel)">{{ id_imovel }}</div>
        </div>
      </div>

      <button type="submit" class="form__submit-btn-home">
        <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
      </button>
    </form>
  </section>
</template>

<script>
import script from '@/mixins/script.js';
import api from '@/axios.js';

export default {
  name: 'Search',
  mixins: [script],
  data() {
    return {
      configuracoes: {
        imagensFundo: [],
      },
      cidadeSuggestions: [],
      bairroSuggestions: [],
      idImovelSuggestions: [],
      displayTiposImoveis: false,
      currentImage: 0
    }
  },
  async mounted() {
    const configuracoes = await this.carregarConfiguracoes();
    if (configuracoes) {
      this.configuracoes = configuracoes;
      console.log('Configurações carregadas:', configuracoes);
    }
    this.closeDropDowns();
    this.startCarousel();
  },
  methods: {
    closeDropDowns() {
      document.addEventListener('click', (event) => {
        const isClickDropImoveis = event.target.id === 'drop-imovel-home';
        const isClickToggleImoveis = event.target.classList.contains('form__select-home');
        const isClickTextToggleImoveis = event.target.id === 'selected-imovel';
        const isClickIconToggleImoveis = event.target.classList.contains('icon-home');

        if (!isClickDropImoveis && !isClickToggleImoveis && !isClickTextToggleImoveis && !isClickIconToggleImoveis) {
          this.displayTiposImoveis = false;
        }

        const isClickDropCidadeBairroIdImovel = event.target.classList.contains('suggestions-home');
        const isClickInputCidadeBairroIdImovel = event.target.classList.contains('form__select-home');

        if (!isClickDropCidadeBairroIdImovel && !isClickInputCidadeBairroIdImovel) {
          this.closeSuggestionHome();
        }
      });
    },

    toggleTab(tab) {
      const alugarBtn = document.getElementById('alugar-select');
      const comprarBtn = document.getElementById('comprar-select');

      if (tab === 'alugar') {
        alugarBtn.classList.add('active');
        comprarBtn.classList.remove('active');
      } else {
        alugarBtn.classList.remove('active');
        comprarBtn.classList.add('active');
      }
    },

    toggleDisplayTiposImoveis() {
      this.displayTiposImoveis = !this.displayTiposImoveis;
    },

    selectImovel(imovel) {
      const selected = document.getElementById('selected-imovel');
      if (imovel === 'Todos imóveis') {
        selected.setAttribute('value', '');
      } else {
        selected.setAttribute('value', imovel);
      }
      selected.textContent = imovel;
    },

    async searchCidades(event) {
      const input = event.target;
      try {
        const response = await fetch(`${this.backendUrl}/cidades`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (!Array.isArray(data.cidades)) {
          throw new Error('Dados recebidos não são uma lista de cidades');
        }

        await this.displayCidadesResults(data.cidades, input.value);
        this.handleInput(input)

      } catch (error) {
        console.error('Erro ao buscar cidades:', error);
        await this.displayCidadesResults([], input.value);
      }
    },

    async displayCidadesResults(results, query) {
      this.cidadeSuggestions = [];

      const normalizedQuery = this.removeAccents(query.toLowerCase());
      const filteredResults = results.filter(cidade => {
        return this.removeAccents(cidade.toLowerCase()).includes(normalizedQuery);
      });

      this.cidadeSuggestions = filteredResults;
    },

    async searchBairros(event) {
      const input = event.target;
      const cidade = document.getElementById('cidade_input').value;
      try {
        const response = await fetch(`${this.backendUrl}/bairros?cidade=${encodeURIComponent(cidade)}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (!Array.isArray(data.bairros)) {
          throw new Error('Dados recebidos não são uma lista de bairros');
        }

        await this.displayBairrosResults(data.bairros, input.value);
        this.handleInput(input)

      } catch (error) {
        console.error('Erro ao buscar bairros:', error);
        await this.displayBairrosResults([], input.value);
      }
    },

    async displayBairrosResults(results, query) {
      this.bairroSuggestions = [];

      const normalizedQuery = this.removeAccents(query.toLowerCase());
      const filteredResults = results.filter(bairro => {
        return this.removeAccents(bairro.toLowerCase()).includes(normalizedQuery);
      });

      this.bairroSuggestions = filteredResults;
    },

    async searchIdImovel(event) {
      const input = event.target;
      try {
        const response = await fetch(`${this.backendUrl}/imoveis`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (!Array.isArray(data.imoveis)) {
          throw new Error('Dados recebidos não são uma lista de imóveis');
        }

        await this.displayIdImovelResults(data.imoveis, input.value);
        this.handleInput(input);

      } catch (error) {
        console.error('Erro ao buscar ID de imóveis:', error);
        await this.displayIdImovelResults([], input.value);
      }
    },

    async displayIdImovelResults(results, query) {
      this.idImovelSuggestions = [];

      const normalizedQuery = this.removeAccents(query.toLowerCase());

      const filteredResults = results
        .filter(imovel => this.removeAccents(imovel.id_imovel.toLowerCase()).includes(normalizedQuery))
        .map(imovel => imovel.id_imovel);

      this.idImovelSuggestions = filteredResults;
    },

    handleInput(input) {
      const clearBtn = input.nextElementSibling;
      clearBtn.style.display = 'block';
    },

    clearInput(inputId) {
      this.closeSuggestionHome();
      const input = document.getElementById(inputId);
      input.value = '';
      const clearBtn = input.nextElementSibling;
      clearBtn.style.display = 'none';
      input.focus();
    },

    async selectSuggestionHome(suggestion, id_input) {
      document.getElementById(id_input).value = suggestion;
      this.closeSuggestionHome();
    },

    closeSuggestionHome() {
      this.cidadeSuggestions = [];
      this.bairroSuggestions = [];
      this.idImovelSuggestions = [];
    },

    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    async buscarImoveisHome() {
      const comercio = document.querySelector('.form__tab-button-home.active').value;
      let categoria = document.getElementById('selected-imovel').getAttribute('value');
      if (categoria === 'Sítio/Chácara') categoria = 'Sitio&Chacara'
      const cidade = document.getElementById('cidade_input').value;
      const bairro = document.getElementById('bairro_input').value;

      let routeName;
      let params = {}
      let query = {}

      if (categoria) {
        routeName = 'ImoveisComercioCategoria';
        params = { comercio: comercio, categoria: categoria };
      } else {
        routeName = 'ImoveisComercio';
        params = { comercio: comercio }
      }

      if (cidade) {
        query.cidade = cidade
      }

      if (bairro) {
        query.bairro = bairro
      }

      try {
        this.$router.push({ name: routeName, params: params, query: query });
      } catch (error) {
        console.error('Erro ao buscar imóveis:', error);
      }
    },

    async selectIdImovelSuggestion(suggestion) {
      let query = { query: suggestion }
      this.closeSuggestionHome();
      this.$router.push({ path: 'imoveis', query: query });
    },
    startCarousel() {
      const tempoTransicao = this.configuracoes.tempoTransicaoImagens ? this.configuracoes.tempoTransicaoImagens * 1000 : 5000;

      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.configuracoes.imagensFundo.length;
      }, tempoTransicao);
    }
  }
}
</script>
<template>
    <div class="gestao-clientes-component">
        <section class="gestaoClientes">
            <div class="container">
                <h2>Gestão de clientes</h2>
                <div class="header-search">
                    <div class="search-container">
                        <input type="text" class="search-bar" placeholder="Pesquisar" v-model="searchQuery"
                            @input="searchClientes" @keypress.enter="applySearch">
                        <div class="clear-button-container" v-show="clearButtonVisible" @click="clearSearchBar">
                            <button class="clear-button">&times;</button>
                        </div>
                        <button class="search-button" @click="applySearch">
                            <img src="@/00-website/assets/imagens/lupa.png" alt="Buscar">
                        </button>
                    </div>
                    <div id="searchResults" class="search-results" v-show="searchResultsVisible">
                        <div v-for="(group, groupName) in filteredGroupedResults" :key="groupName">
                            <div><strong>{{ groupName }}</strong></div>
                            <div v-for="item in group" :key="item" class="selectable-option"
                                @click="selectSuggestion(item)">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="button-header">
                        <button id="new" @click="cadastrarNovoCliente">Cadastrar novo cliente</button>
                    </div>
                </div>

                <div class="divTable">
                    <table>
                        <thead>
                            <tr>
                                <th @click="changeOrder('nome')">
                                    <div class="nome">
                                        Nome
                                        <span class="icon"
                                            :class="{ rotate: currentSortField === 'nome' && currentSortOrder === 'asc' }"></span>
                                    </div>
                                </th>
                                <th @click="changeOrder('sobrenome')">
                                    <div class="sobrenome">
                                        Sobrenome
                                        <span class="icon"
                                            :class="{ rotate: currentSortField === 'sobrenome' && currentSortOrder === 'asc' }"></span>
                                    </div>
                                </th>
                                <th @click="changeOrder('email')">
                                    <div class="email">
                                        E-mail
                                        <span class="icon"
                                            :class="{ rotate: currentSortField === 'email' && currentSortOrder === 'asc' }"></span>
                                    </div>
                                </th>
                                <th @click="changeOrder('telefoneCelular')">
                                    <div class="telefoneCelular">
                                        Celular
                                        <span class="icon"
                                            :class="{ rotate: currentSortField === 'telefoneCelular' && currentSortOrder === 'asc' }"></span>
                                    </div>
                                </th>
                                <th @click="changeOrder('documento')">
                                    <div class="documento">
                                        Documento
                                        <span class="icon"
                                            :class="{ rotate: currentSortField === 'documento' && currentSortOrder === 'asc' }"></span>
                                    </div>
                                </th>
                                <th class="acao">Editar/Excluir</th>
                            </tr>
                        </thead>

                        <tbody id="clientesTableBody">
                            <tr v-for="cliente in clientesRenderizados" :key="cliente._id">
                                <td>{{ cliente.nome }}</td>
                                <td>{{ cliente.sobrenome }}</td>
                                <td>{{ cliente.email }}</td>
                                <td>{{ formatarTelefone(cliente.telefoneCelular) }}</td>
                                <td>{{ formatarDocumento(cliente.documento) }}</td>
                                <td class="acao">
                                    <button class="editar" :id="'editar-' + cliente.email"
                                        @click="buscarDadosCliente(cliente._id)"></button>
                                    <button class="excluir" :id="'excluir-' + cliente.email"
                                        @click="excluirCliente(cliente._id)"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="pagination">
                    <div class="custom-select">
                        <button id="btn-page-number" class="page-number"
                            @click="toggleDropdown('pagination-content')">{{
                                itemsPerPage }}</button>
                        <div id="pagination-content" class="paginationDropdown-content">
                            <button @click="changeItemsPerPage(20)">20</button>
                            <button @click="changeItemsPerPage(50)">50</button>
                            <button @click="changeItemsPerPage(100)">100</button>
                        </div>
                    </div>
                    <div class="btn-pagination">
                        <button class="btn-navigation" @click="previousPage"
                            :disabled="currentPage === 1">&#60;</button>
                        <span id="currentPageIndicator">{{ currentPage }}</span>
                        <button class="btn-navigation" @click="nextPage"
                            :disabled="currentPage >= totalPages">&#62;</button>
                    </div>
                </div>
            </div>
        </section>
        <Popup ref="popup" />
    </div>
</template>

<script>
import api from '@/axios.js';
import Popup from '@/mixins/Popup.vue';
import script from '@/mixins/script.js';

export default {
    name: 'GestaoClientes',
    mixins: [script],
    components: {
        Popup
    },
    data() {
        return {
            clearButtonVisible: false,
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 1,
            searchQuery: '',
            searchResultsVisible: false,
            currentSortField: '_id',
            currentSortOrder: 'desc',
            clientes: [],
            clientesRenderizados: [],
            groupedResults: {
                'Nomes': new Set(),
                'Sobrenomes': new Set(),
                'Emails': new Set(),
                'Celulares': new Set(),
                'CPFs': new Set(),
            },
        };
    },
    computed: {
        filteredGroupedResults() {
            return Object.fromEntries(
                Object.entries(this.groupedResults).filter(([key, value]) => value.size > 0)
            );
        }
    },
    methods: {
        async carregarClientes() {
            try {
                const sortFieldQuery = this.currentSortField ? `&sortField=${this.currentSortField}` : '';
                const sortOrderQuery = this.currentSortOrder ? `&sortOrder=${this.currentSortOrder}` : '';
                const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/clientes?page=${this.currentPage}&limit=${this.itemsPerPage}${sortFieldQuery}${sortOrderQuery}&query=${this.searchQuery}`;

                const response = await api.get(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                });

                const data = await response.data;
                this.clientes = data.clientes || [];
                this.totalPages = Math.ceil((data.totalClientes || 0) / this.itemsPerPage);
                this.clientesRenderizados = this.clientes;

            } catch (error) {
                console.error('Erro ao carregar clientes:', error);
            }
        },
        async buscarDadosCliente(id) {
            try {
                const response = await api.get(`${process.env.VUE_APP_BACKEND_URL}/clientes/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                });

                const cliente = await response.data;
                this.$router.push({ name: 'EditarCliente', params: { id: cliente._id } });
            } catch (error) {
                console.error('Erro ao buscar o cliente para edição:', error);
                await this.$refs.popup.showAlert('Erro ao buscar o cliente para edição: ' + error.response.data);
            }
        },
        async excluirCliente(id) {
            const emailUsuario = this.clientes.find((cliente) => cliente._id === id).email
            await this.$refs.popup.showConfirm(`Tem certeza que deseja excluir o cliente ${emailUsuario}?`, async (confirmed) => {
                if (confirmed) {

                    try {
                        await api.delete(`${process.env.VUE_APP_BACKEND_URL}/clientes/${id}`, {
                            headers: {
                                'Authorization': `Bearer ${this.getToken()}`
                            }
                        });

                        await this.carregarClientes();
                        await this.$refs.popup.showAlert('Cliente excluído com sucesso!');
                    } catch (error) {
                        console.error('Erro ao excluir cliente:', error);
                        await this.$refs.popup.showAlert('Erro ao excluir cliente: ' + error.response.data);
                    }
                }
            });
        },
        async searchClientes() {
            if (this.searchQuery.length > 0) {
                this.toggleClearButton();
                this.searchResultsVisible = true;
                await this.displaySearchResults(this.searchQuery);
            } else {
                this.clearSearchBar();
            }
        },
        async displaySearchResults(query) {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/clientes?query=${query}`, {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    },
                });

                const data = await response.json();
                const results = data.clientes || [];

                const newGroupedResults = {
                    'Nomes': new Set(),
                    'Sobrenomes': new Set(),
                    'Emails': new Set(),
                    'Celulares': new Set(),
                    'Documentos': new Set(),
                };

                const normalizedQuery = this.removeAccents(query.toLowerCase());

                results.forEach(cliente => {
                    if (this.removeAccents(cliente.nome.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Nomes'].add(cliente.nome);
                    if (this.removeAccents(cliente.sobrenome.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Sobrenomes'].add(cliente.sobrenome);
                    if (this.removeAccents(cliente.email.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Emails'].add(cliente.email);
                    if (this.removeAccents(cliente.telefoneCelular.toString().toLowerCase()).includes(normalizedQuery)) newGroupedResults['Celulares'].add(cliente.telefoneCelular);
                    if (this.removeAccents(cliente.documento.toLowerCase()).includes(normalizedQuery)) newGroupedResults['Documentos'].add(cliente.documento);
                });

                this.groupedResults = newGroupedResults;
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
                this.groupedResults = {
                    'Nomes': new Set(),
                    'Sobrenomes': new Set(),
                    'Emails': new Set(),
                    'Celulares': new Set(),
                    'CPFs': new Set(),
                };
            }
        },
        toggleClearButton() {
            this.clearButtonVisible = this.searchQuery.length > 0;
        },
        clearSearchBar() {
            this.searchQuery = '';
            this.clearButtonVisible = false;
            this.searchResultsVisible = false;
            this.carregarClientes();
        },
        async applySearch() {
            await this.carregarClientes();
            this.searchResultsVisible = false;
        },
        async changeOrder(field) {
            if (this.currentSortField === field) {
                this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortField = field;
                this.currentSortOrder = 'asc';
            }
            await this.carregarClientes();
        },
        async changeItemsPerPage(items) {
            this.itemsPerPage = items;
            this.currentPage = 1;
            await this.carregarClientes();
            this.toggleDropdown('pagination-content');
        },
        async nextPage() {
            if (this.clientesRenderizados.length >= this.itemsPerPage) {
                this.currentPage += 1;
                await this.carregarClientes();
            }
        },
        async previousPage() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
                await this.carregarClientes();
            }
        },
        toggleDropdown(idElement) {
            const options = document.getElementById(idElement);
            if (options.classList.contains('show')) {
                options.classList.remove('show');
            } else {
                options.classList.add('show');
            }
        },
        selectSuggestion(suggestion) {
            this.searchQuery = suggestion;
            this.applySearch();
        },
        removeAccents(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        getToken() {
            return localStorage.getItem('token');
        },
        cadastrarNovoCliente() {
            window.location.href = '/admin/clientes/cadastrar';
        }
    },
    mounted() {
        this.carregarClientes();
    },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/gestaoClientes.css';
</style>
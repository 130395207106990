<template>
  <div class="cadastro-imoveis-component">
    <section class="cadastro-imoveis">
      <div class="container">
        <div class="header-property">
          <h2>{{ $route.params.id ? 'Editar Imóvel' : 'Cadastrar novo imóvel' }}</h2>
          <div class="close-property" @click="closeForm">X</div>
        </div>
        <hr>
        <form id="form-property" @submit.prevent="showSaveConfirmation">
          <!-- Informações Básicas -->
          <div class="section">
            <h3>Informações Básicas</h3>
            <div class="grid-container" id="grid-select">
              <div class="group-input">
                <h4>Categoria*</h4>
                <div class="select" id="select-categoria" @click="toggleDisplayOpcoesCategoria"
                  @keypress.enter="toggleDisplayOpcoesCategoria">
                  <span v-if="form.categoria" id="selected-categoria">{{ form.categoria }}</span>
                  <span v-else id="selected-categoria">Selecione</span>
                  <span v-if="form.categoria" class="remove-categoria-btn" @click.stop="removeCategoria">X</span>
                  <span v-else class="icon-expanded"></span>
                  <ul id="drop-categoria" class="dropdown" :class="{ 'show': displayOpcoesCategoria }">
                    <div class="filter-categoria">
                      <input type="text" ref="filterCategoriaInput" @click.stop
                        @input="filtrarCategorias($event.target.value)" placeholder="Digite para filtrar">
                    </div>
                    <div class="opcoes-container">
                      <li v-for="categoria in categoriasFiltradas" :key="categoria" class="selectable-option">
                        <label class="radio-container">
                          <span>{{ categoria }}</span>
                          <input type="radio" class="radio" :value="categoria" v-model="form.categoria" />
                        </label>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="group-input">
                <h4>Comércio*</h4>
                <div class="select-group">
                  <div v-for="tipo in tiposComercio" :key="tipo">
                    <input type="checkbox" :id="'comercio-' + tipo" :value="tipo" v-model="form.comercio">
                    <label :for="'comercio-' + tipo">{{ tipo }}</label>
                  </div>
                </div>
              </div>
              <div class="group-input">
                <div class="input-title">
                  <h4 for="proprietario">Proprietário</h4>
                  <span class="sub-text">(Não exibido no site)</span>
                </div>
                <div class="select" id="select-proprietario" @click="toggleDisplayOpcoesProprietario"
                  @keypress.enter="toggleDisplayOpcoesProprietario">
                  <span v-if="form.proprietario" id="selected-proprietario">
                    {{ selectedProprietario.nome }} {{ selectedProprietario.sobrenome }}
                  </span>
                  <span v-else id="selected-proprietario">Selecione</span>
                  <span v-if="form.proprietario" class="remove-proprietario-btn"
                    @click.stop="removeProprietario">X</span>
                  <span v-else class="icon-expanded" id="icon-expanded-proprietario"></span>
                  <ul id="drop-proprietario" class="dropdown" :class="{ 'show': displayOpcoesProprietarios }">
                    <div class="filter-proprietario">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M472 168H40a24 24 0 0 1 0-48h432a24 24 0 0 1 0 48m-80 112H120a24 24 0 0 1 0-48h272a24 24 0 0 1 0 48m-96 112h-80a24 24 0 0 1 0-48h80a24 24 0 0 1 0 48" />
                      </svg>
                      <input type="text" ref="filterProprietarioInput" @click.stop
                        @input="carregarClientes($event.target.value)" placeholder="Digite nome/documento">
                    </div>
                    <div class="opcoes-container">
                      <li v-for="cliente in opcoesProprietarios" :key="cliente._id" class="selectable-option">
                        <label class="radio-container">
                          <span>{{ cliente.nome }} {{ cliente.sobrenome }}</span>
                          <input type="radio" class="radio" :value="cliente._id" v-model="form.proprietario" />
                        </label>
                      </li>
                    </div>
                    <div class="add-new-cliente-container">
                      <button type="button" class="add-cliente-button" @click.prevent="openModalAddCliente">
                        <ion-icon name="add-outline"></ion-icon>Novo cliente
                      </button>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <hr>
          </div>

          <!-- Modal de cadastro de cliente -->
          <section v-show="displayModalAddCliente" class="modal-cliente">
            <div class="modal-cliente-container">
              <div class="header-property">
                <h2>Informações do cliente</h2>
                <div class="close-property" @click="closeModalAddCliente">X</div>
              </div>
              <hr>
              <form id="form-cliente" @submit.prevent="cadastrarCliente">
                <div class="section">
                  <h3>Dados Pessoais</h3>
                  <div class="grid-container" id="grid-pessoais">
                    <div class="group-input">
                      <h4>Nome*</h4>
                      <input type="text" v-model="form_cliente.nome" tabindex="1" />
                      <div class="error-message-input" v-if="errors_cliente.nome">{{ errors_cliente.nome }}</div>
                    </div>
                    <div class="group-input">
                      <h4>Sobrenome*</h4>
                      <input type="text" v-model="form_cliente.sobrenome" tabindex="2" />
                      <div class="error-message-input" v-if="errors_cliente.sobrenome">{{ errors_cliente.sobrenome }}
                      </div>
                    </div>
                    <div class="group-input">
                      <h4>Data de Nascimento*</h4>
                      <input type="date" v-model="form_cliente.dataNascimento" tabindex="3" />
                    </div>
                    <div class="group-input">
                      <h4>Documento*</h4>
                      <input type="text" placeholder="CPF/CNPJ" v-model="form_cliente.documento"
                        @input="form_cliente.documento = formatarDocumento($event.target.value)" tabindex="4" />
                      <div class="error-message-input" v-if="errors_cliente.documento">{{ errors_cliente.documento }}
                      </div>
                    </div>
                  </div>
                  <hr>
                </div>
                <!-- Endereço Atual -->
                <div class="section">
                  <h3>Endereço Atual</h3>
                  <div class="grid-container">
                    <div class="group-input">
                      <h4>CEP</h4>
                      <input type="text" v-model="form_cliente.endereco.cep"
                        @input="form_cliente.endereco.cep = formatarCEP($event.target.value), inserirEnderecoBuscadoCliente($event.target.value)"
                        tabindex="5" />
                    </div>
                    <div class="group-input">
                      <h4>Rua</h4>
                      <input type="text" v-model="form_cliente.endereco.rua" tabindex="6" />
                    </div>
                    <div class="group-input">
                      <h4>Número*</h4>
                      <input type="number" v-model="form_cliente.endereco.numero" tabindex="7" />
                    </div>
                    <div class="group-input">
                      <h4>Complemento</h4>
                      <input type="text" v-model="form_cliente.endereco.complemento" tabindex="8" />
                    </div>
                    <div class="group-input">
                      <h4>Bairro</h4>
                      <input type="text" v-model="form_cliente.endereco.bairro" tabindex="9" />
                    </div>
                    <div class="group-input">
                      <h4>Cidade*</h4>
                      <input type="text" v-model="form_cliente.endereco.cidade" tabindex="10" />
                    </div>
                    <div class="group-input">
                      <h4>Estado*</h4>
                      <input type="text" v-model="form_cliente.endereco.estado"
                        @input="form_cliente.endereco.estado = formatarEstado($event.target.value)" tabindex="11" />
                    </div>
                  </div>
                  <hr>
                </div>
                <!-- Informações de Contato -->
                <div class="section">
                  <h3>Dados de Contato</h3>
                  <div class="grid-container">
                    <div class="group-input">
                      <h4>E-mail*</h4>
                      <input type="email" v-model="form_cliente.email" tabindex="12" />
                      <div class="error-message-input" v-if="errors_cliente.email">{{ errors_cliente.email }}</div>
                    </div>
                    <div class="group-input">
                      <h4>Telefone Celular*</h4>
                      <input type="text" v-model="form_cliente.telefoneCelular"
                        @input="form_cliente.telefoneCelular = formatarTelefone($event.target.value)" tabindex="13" />
                    </div>
                  </div>
                  <hr>
                </div>
                <!-- Botões -->
                <div class="btn-container">
                  <button type="button" class="btn-anexar" @click="abrirModalUploadDocumentos">Anexar
                    Documentos</button>
                  <div class="btn-group-right">
                    <button type="button" class="button" @click="closeModalAddCliente">Cancelar</button>
                    <button type="submit" class="btn-salvar-form" :disabled="!isFormClienteValid">Salvar</button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <!-- Modal de upload de arquivos -->
          <section class="modal-upload" v-if="mostrarModalUploadDocumento">
            <div class="modal-container">
              <h2>Upload de Documentos do Cliente</h2>
              <div class="upload-container">
                <div ref="preview_documento" class="preview-container">
                  <div ref="uploadPlaceholder_documento" class="image-upload upload-placeholder">
                    <label for="documentoCliente">
                      <div class="upload-placeholder">
                        <span>+</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <input type="file" id="documentoCliente" accept="image/*" multiple style="display: none"
                @change="carregarDocumentosSelecionados">
              <div class="btn-modal">
                <button type="button" class="button" @click="cancelarModalUploadDocumentos">Voltar</button>
                <button type="button" class="btn-salvar-documentos" @click="saveAndCloseModalDocumentos">Salvar
                  documentos</button>
              </div>
            </div>
          </section>

          <!-- Dados do Imóvel -->
          <div class="section">
            <h3>Dados do Imóvel</h3>
            <div class="grid-container">
              <div class="group-input" :class="{ inputForTerreno: form.categoria === 'Terreno' }">
                <h4 for="areaTotal">Área Total</h4>
                <input id="areaTotal" type="text" placeholder="0 m²"
                  @input="form.areaTotal = formatarArea($event.target.value, true)"
                  @focus="form.areaTotal = formatarArea($event.target.value, true)"
                  @blur="form.areaTotal = formatarArea($event.target.value)" v-model="form.areaTotal" />
              </div>
              <div class="group-input" v-if="this.form.categoria !== 'Terreno'">
                <h4 for="areaConstruida">Área construída</h4>
                <input id="areaConstruida" type="text" placeholder="0 m²"
                  @input="form.areaConstruida = formatarArea($event.target.value, true)"
                  @focus="form.areaConstruida = formatarArea($event.target.value, true)"
                  @blur="form.areaConstruida = formatarArea($event.target.value)" v-model="form.areaConstruida" />
              </div>
              <div class="group-input" v-if="this.form.categoria !== 'Terreno'">
                <h4 for="quartos">Quartos</h4>
                <input id="quartos" type="number" placeholder="0" v-model="form.quartos" />
              </div>
              <div class="group-input" v-if="this.form.categoria !== 'Terreno'">
                <h4 for="banheiros">Banheiros</h4>
                <input id="banheiros" type="number" placeholder="0" v-model="form.banheiros" />
              </div>
              <div class="group-input" v-if="this.form.categoria !== 'Terreno'">
                <h4 for="garagem">Vagas na garagem</h4>
                <input id="garagem" type="number" placeholder="0" v-model="form.garagem" />
              </div>
            </div>
            <hr>
          </div>
          <!-- Endereço -->
          <div class="section">
            <h3>Endereço</h3>
            <div class="grid-container" id="grid-endereco">
              <div class="group-input">
                <h4 for="cep">CEP</h4>
                <input id="cep" type="text" placeholder="00000-000"
                  @input="form.cep = formatarCEP($event.target.value), inserirEnderecoBuscado($event.target.value)"
                  v-model="form.cep" />
              </div>
              <div class="group-input">
                <h4 for="rua">Rua</h4>
                <input id="rua" type="text" placeholder="Avenida Paulista" v-model="form.rua" />
              </div>
              <div class="group-input">
                <h4 for="numero">Número</h4>
                <input id="numero" type="number" placeholder="000" v-model="form.numero" />
              </div>
              <div class="group-input">
                <h4 for="bairro">Bairro</h4>
                <input id="bairro" type="text" placeholder="Centro" v-model="form.bairro" />
              </div>
              <div class="group-input">
                <h4 for="cidade">Cidade*</h4>
                <input id="cidade" type="text" placeholder="São Paulo" v-model="form.cidade" />
              </div>
              <div class="group-input">
                <h4 for="estado">Estado*</h4>
                <input id="estado" type="text" placeholder="SP"
                  @input="form.estado = formatarEstado($event.target.value)" v-model="form.estado" />
              </div>
            </div>
            <hr>
          </div>
          <!-- Coordenadas -->
          <div class="section">
            <h3>Coordenadas</h3>
            <div class="grid-container">
              <div class="group-input">
                <h4 for="latitude">Latitude</h4>
                <input id="latitude" type="text" placeholder="-00.000000" v-model="form.latitude" />
              </div>
              <div class="group-input">
                <h4 for="longitude">Longitude</h4>
                <input id="longitude" type="text" placeholder="-99.999999" v-model="form.longitude" />
              </div>
            </div>
            <hr>
          </div>
          <!-- Valores -->
          <div class="section">
            <div class="input-title">
              <h3>Valores</h3>
              <span class="sub-text">(Selecione ocultar para não exibir o valor ou consultar para marcar consulte-nos no
                site)</span>
            </div>
            <div class="grid-container">
              <div v-if="form.comercio.includes('Venda')" class="group-input">
                <h4 for="valorVenda">Valor do imóvel</h4>
                <div class="input-group">
                  <input id="valorVenda" type="text" placeholder="R$ 0,00"
                    @input="form.valorVenda = formatarValor($event.target.value)" v-model="form.valorVenda" />
                  <div class="consultar-checkbox">
                    <input type="checkbox" v-model="form.consultarValorVenda" id="consultarValorVenda">
                    <label for="consultarValorVenda">Consultar</label>
                  </div>
                </div>
              </div>
              <div v-if="form.comercio.includes('Aluguel')" class="group-input">
                <h4 for="valorAluguel">Valor do aluguel</h4>
                <div class="input-group">
                  <input id="valorAluguel" type="text" placeholder="R$ 0,00"
                    @input="form.valorAluguel = formatarValor($event.target.value, true, true)"
                    @focus="form.valorAluguel = formatarValor($event.target.value, true, true)"
                    @blur="form.valorAluguel = formatarValor($event.target.value, true)" v-model="form.valorAluguel" />
                  <div class="consultar-checkbox">
                    <input type="checkbox" v-model="form.consultarValorAluguel" id="consultarValorAluguel">
                    <label for="consultarValorAluguel">Consultar</label>
                  </div>
                </div>
              </div>
              <div class="group-input">
                <h4 for="iptu">IPTU</h4>
                <div class="input-group">
                  <input id="iptu" type="text" placeholder="R$ 0,00"
                    @input="form.iptu = formatarValor($event.target.value)" v-model="form.iptu" />
                  <div class="consultar-checkbox">
                    <input type="checkbox" v-model="form.ocultarIPTU" id="ocultarIPTU">
                    <label for="ocultarIPTU">Ocultar</label>
                  </div>
                </div>
              </div>
              <div class="group-input">
                <h4 for="condominio">Condomínio</h4>
                <div class="input-group">
                  <input id="condominio" type="text" placeholder="R$ 0,00"
                    @input="form.condominio = formatarValor($event.target.value)" v-model="form.condominio" />
                  <div class="ocultar-checkbox">
                    <input type="checkbox" v-model="form.ocultarCondominio" id="ocultarCondominio">
                    <label for="ocultarCondominio">Ocultar</label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <!-- Condições de Pagamento -->
          <div class="section">
            <h3>Condições de Pagamento</h3>
            <div class="grid-container" id="grid-select">
              <div class="group-input">
                <h4>Parcelamento*</h4>
                <div class="select-group">
                  <div>
                    <input type="radio" id="parcelamento-sim" name="parcelamento" value=true
                      v-model="form.parcelamento">
                    <label for="parcelamento-sim">Sim</label>
                  </div>
                  <div>
                    <input type="radio" id="parcelamento-nao" name="parcelamento" value=false
                      v-model="form.parcelamento">
                    <label for="parcelamento-nao">Não</label>
                  </div>
                </div>
              </div>
              <div class="group-input">
                <h4>Financiamento*</h4>
                <div class="select-group">
                  <div>
                    <input type="radio" id="financiamento-sim" name="financiamento" value=true
                      v-model="form.financiamento">
                    <label for="financiamento-sim">Sim</label>
                  </div>
                  <div>
                    <input type="radio" id="financiamento-nao" name="financiamento" value=false
                      v-model="form.financiamento">
                    <label for="financiamento-nao">Não</label>
                  </div>
                </div>
              </div>
              <div class="group-input">
                <h4>Negociação*</h4>
                <div class="select-group">
                  <div>
                    <input type="radio" id="negociacao-sim" name="negociacao" value=true v-model="form.negociacao">
                    <label for="negociacao-sim">Sim</label>
                  </div>
                  <div>
                    <input type="radio" id="negociacao-nao" name="negociacao" value=false v-model="form.negociacao">
                    <label for="negociacao-nao">Não</label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <!-- Descrição e Itens -->
          <div class="section">
            <div class="grid-container" id="grid-descricao">
              <div class="group-input">
                <div class="input-title">
                  <h3>Descrição do Imóvel*</h3>
                  <span class="sub-text">(Descrever o imóvel)</span>
                </div>
                <textarea id="descricaoImovel" rows="3"
                  placeholder="Esta casa oferece todo o conforto e espaço que você precisa. Com três quartos espaçosos, sala de estar ..."
                  v-model="form.descricaoImovel"></textarea>
              </div>
              <div class="group-input" v-if="this.form.categoria !== 'Terreno'">
                <div class="input-title">
                  <h3 for="detalhesImovel">Itens disponíveis no Imóvel</h3>
                  <span class="sub-text">(Separar por vírgulas)</span>
                </div>
                <textarea id="detalhesImovel" rows="3" placeholder="Churrasqueira, Lavanderia, Dispensa ..."
                  v-model="form.detalhesImovel"></textarea>
              </div>
              <div class="group-input">
                <div class="input-title">
                  <h3 for="proximidades">Proximidades</h3>
                  <span class="sub-text">(Separar por vírgulas)</span>
                </div>
                <textarea id="proximidadesImovel" rows="3" placeholder="Farmácia, Restaurantes, Escola ..."
                  v-model="form.proximidades"></textarea>
              </div>
            </div>
          </div>
          <!-- Botões -->
          <div class="btn-container">
            <button type="button" class="btn-anexar" @click="abrirModalUpload">Anexar Imagens</button>
            <div class="btn-group-right">
              <button type="button" class="button" @click="closeForm">Cancelar</button>
              <button type="submit" class="btn-salvar-form" :disabled="!isFormValid">Salvar</button>
            </div>
          </div>
        </form>
      </div>
      <!-- Modal de Upload de Imagens -->
      <section class="modal-upload" v-if="mostrarModalUpload">
        <div class="modal-container">
          <h2>Upload de Imagens do Imóvel</h2>
          <p>Total: {{ totalImagensCarregadas }} de {{ MAX_IMAGES }} imagens. Ainda é possível enviar {{
            imagensRestantes }} imagens.</p>
          <div class="upload-container">
            <div ref="preview" class="preview-container">
              <div ref="uploadPlaceholder" class="image-upload upload-placeholder">
                <label for="imagemImovel">
                  <div class="upload-placeholder">
                    <span>+</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <input type="file" id="imagemImovel" accept="image/*" multiple style="display: none"
            @change="carregarImagensSelecionadas">
          <div class="btn-modal">
            <button type="button" class="btn-excluir" @click="removeAllImages">Remover todas</button>
            <div class="btn-group-right">
              <button type="button" class="button" @click="cancelarModalUpload">Voltar</button>
              <button type="button" class="btn-salvar-imagens" @click="saveAndCloseModal">Salvar imagens</button>
            </div>
          </div>
        </div>
      </section>
    </section>
    <Popup ref="popup" />
    <Loading :loading="isLoading" />
  </div>
</template>

<script>
import api from '@/axios.js';
import Sortable from 'sortablejs';
import Popup from '@/mixins/Popup.vue';
import Loading from '@/mixins/Loading.vue';
import { emitter } from '@/router/admin.js';
import script from '@/mixins/script.js';

export default {
  name: 'CadastroImoveis',
  mixins: [script],
  data() {
    return {
      form: {
        categoria: '',
        comercio: [],
        proprietario: '',
        areaTotal: '',
        areaConstruida: '',
        quartos: '',
        banheiros: '',
        garagem: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        latitude: '',
        longitude: '',
        valorVenda: '',
        valorAluguel: '',
        consultarValorVenda: false,
        consultarValorAluguel: false,
        iptu: '',
        condominio: '',
        ocultarIPTU: false,
        ocultarCondominio: false,
        parcelamento: '',
        financiamento: '',
        negociacao: '',
        detalhesImovel: '',
        proximidades: '',
        descricaoImovel: ''
      },
      form_cliente: {
        nome: '',
        sobrenome: '',
        dataNascimento: '',
        documento: '',
        identidade: '',
        nacionalidade: '',
        estadoCivil: '',
        profissao: '',
        rendaMensal: '',
        email: '',
        telefoneResidencial: '',
        telefoneCelular: '',
        endereco: {
          cep: '',
          rua: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: '',
          estado: ''
        },
        conjuge: {
          nome_conjuge: '',
          doc_conjuge: '',
          identidade_conjuge: '',
          telefone_conjuge: ''
        },
        referencia: {
          nome_referencia: '',
          telefone_referencia: '',
          relacao_referencia: ''
        }
      },
      initialFormState: {},
      mostrarModalUpload: false,
      categorias: ['Casa', 'Apartamento', 'Terreno', 'Kitnet', 'Comercial', 'Sitio', 'Chacara'],
      tiposComercio: ['Venda', 'Aluguel'],
      imagensSalvas: [],
      imagensTemporarias: [],
      MAX_IMAGES: 30,
      MAX_FILES: 30,
      opcoesProprietarios: [],
      documentosTemporarios: [],
      documentosSalvos: [],
      displayOpcoesCategoria: false,
      categoriasFiltradas: this.categorias,
      displayOpcoesProprietarios: false,
      displayModalAddCliente: false,
      displayOpcoesEstadoCivil: false,
      mostrarModalUploadDocumento: false,
      opcoesEstadoCivil: ['Solteiro', 'Casado', 'Separado', 'Divorciado', 'Viúvo'],
      errors_cliente: {
        nome: '',
        sobrenome: '',
        documento: '',
        email: ''
      },
      isLoading: false
    };
  },
  computed: {
    isFormValid() {
      return this.form.categoria && this.form.comercio.length > 0 && this.form.cidade && this.form.estado && (this.form.parcelamento === 'true' || this.form.parcelamento === 'false') && (this.form.financiamento === 'true' || this.form.financiamento === 'false') && (this.form.negociacao === 'true' || this.form.negociacao === 'false') && this.form.descricaoImovel
    },
    isFormClienteValid() {
      return this.form_cliente.nome && this.form_cliente.sobrenome && this.form_cliente.dataNascimento && this.form_cliente.documento && this.form_cliente.endereco.rua && this.form_cliente.endereco.numero && this.form_cliente.endereco.bairro && this.form_cliente.endereco.cidade && this.form_cliente.endereco.estado && this.form_cliente.email && this.form_cliente.telefoneCelular;
    },
    isEdit() {
      return Boolean(this.$route.params.id);
    },
    selectedProprietario() {
      const cliente = this.opcoesProprietarios.find(cliente => cliente._id === this.form.proprietario);
      return cliente ? cliente : '';
    },
    totalImagensCarregadas() {
      return this.imagensTemporarias.length;
    },
    imagensRestantes() {
      return this.MAX_IMAGES - this.totalImagensCarregadas;
    },
  },
  async created() {
    if (this.$route.params.id) {
      await this.carregarDadosImovel(this.$route.params.id);
    }
    this.saveInitialFormState();
  },
  methods: {
    saveInitialFormState() {
      this.initialFormState = JSON.parse(JSON.stringify(this.form));
      this.imagensTemporarias = JSON.parse(JSON.stringify(this.imagensSalvas));
      console.log('Initial form state saved:', this.initialFormState);
      console.log('Initial image state saved:', this.imagensTemporarias);
    },
    hasFormChanged() {
      const formChanged = JSON.stringify(this.form) !== JSON.stringify(this.initialFormState);
      const imagesChanged = !this.compareArrays(this.imagensTemporarias, this.imagensSalvas);
      console.log('Checking if form has changed...');
      console.log('Form changed:', formChanged);
      console.log('Images changed:', imagesChanged);
      return formChanged || imagesChanged;
    },
    compareArrays(array1, array2) {
      console.log('Comparing image arrays:', array1, array2);
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].src !== array2[i].src || array1[i].ordem !== array2[i].ordem) {
          console.log('Image difference found at index', i);
          return false;
        }
      }
      return true;
    },
    closeForm() {
      if (this.hasFormChanged()) {
        this.$refs.popup.showConfirm('Deseja sair sem salvar as informações?', (confirmed) => {
          if (confirmed) {
            this.$router.push('/admin/imoveis');
          }
        });
      } else {
        this.$router.push('/admin/imoveis');
      }
    },
    showSaveConfirmation() {
      this.$refs.popup.showConfirm('Deseja salvar o imóvel?', (confirmed) => {
        if (confirmed) {
          this.handleSubmit(this.isEdit ? 'update' : 'create');
        }
      });
    },
    async handleSubmit(actionType) {
      this.isLoading = true
      const cepSemMascara = this.form.cep.replace('-', '');
      const comercioArray = Array.isArray(this.form.comercio) ? this.form.comercio : this.form.comercio.split(',');

      const payload = {
        comercio: comercioArray,
        categoria: this.form.categoria,
        cep: this.form.cep ? cepSemMascara : 0,
        rua: this.form.rua,
        numero: this.form.numero ? this.form.numero : 0,
        bairro: this.form.bairro,
        cidade: this.form.cidade,
        estado: this.form.estado,
        idGeoLocation: this.form.latitude && this.form.longitude ? `${this.form.latitude}, ${this.form.longitude}` : '0, 0',
        areaTotal: this.form.areaTotal ? parseFloat(this.form.areaTotal.replace(' m²', '').replace(',', '.')) : 0,
        areaConstruida: this.form.areaConstruida ? parseFloat(this.form.areaConstruida.replace(' m²', '').replace(',', '.')) : 0,
        quartos: this.form.quartos ? this.form.quartos : 0,
        banheiros: this.form.banheiros ? this.form.banheiros : 0,
        garagem: this.form.garagem ? this.form.garagem : 0,
        valorVenda: this.form.valorVenda ? (this.form.valorVenda !== '' ? parseFloat(this.form.valorVenda.replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : this.form.valorVenda) : 0,
        valorAluguel: this.form.valorAluguel ? (this.form.valorAluguel !== '' ? parseFloat(this.form.valorAluguel.replace('R$ ', '').replace(/\./g, '').replace(',', '.').replace('/mês', '')) : this.form.valorAluguel) : 0,
        consultarValorVenda: comercioArray.includes('Venda') && !this.form.valorVenda ? true : this.form.consultarValorVenda,
        consultarValorAluguel: comercioArray.includes('Aluguel') && !this.form.valorAluguel ? true : this.form.consultarValorAluguel,
        iptu: this.form.iptu ? (this.form.iptu !== '' ? parseFloat(this.form.iptu.replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : this.form.iptu) : 0,
        condominio: this.form.condominio ? (this.form.condominio !== '' ? parseFloat(this.form.condominio.replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : this.form.condominio) : 0,
        ocultarIPTU: !this.form.iptu ? true : this.form.ocultarIPTU,
        ocultarCondominio: !this.form.condominio ? true : this.form.ocultarCondominio,
        id_proprietario: this.form.proprietario ? this.selectedProprietario._id : null,
        nome_proprietario: this.form.proprietario ? `${this.selectedProprietario.nome} ${this.selectedProprietario.sobrenome}` : '',
        doc_proprietario: this.form.proprietario ? this.selectedProprietario.documento : 0,
        descricaoImovel: this.form.descricaoImovel,
        detalhesImovel: this.form.detalhesImovel.trim() ? this.form.detalhesImovel.split(',').map(item => item.trim()) : [],
        proximidades: this.form.proximidades.trim() ? this.form.proximidades.split(',').map(item => item.trim()) : [],
        parcelamento: this.form.parcelamento,
        financiamento: this.form.financiamento,
        negociacao: this.form.negociacao
      };

      const url = this.isEdit
        ? `${process.env.VUE_APP_BACKEND_URL}/imoveis/${this.$route.params.id}`
        : `${process.env.VUE_APP_BACKEND_URL}/imoveis`;

      const method = this.isEdit ? 'PUT' : 'POST';

      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }

        const data = await response.json();
        const newIdImovel = data.id_imovel || this.form.id_imovel;

        await this.enviarImagens(newIdImovel);
        this.showSuccessAndRedirect(`Imóvel ${newIdImovel} ${actionType === 'create' ? 'cadastrado' : 'atualizado'} com sucesso!`);

      } catch (error) {
        console.error('Erro ao salvar imóvel:', error);
        if (error.response.data !== 'Token expired.') {
          await this.$refs.popup.showAlert('Erro ao salvar imóvel: ' + error.response.data);
        }
        await this.$refs.popup.showAlert('Erro ao salvar imóvel: ' + error.message);
      } finally {
        this.isLoading = false
      }
    },
    showSuccessAndRedirect(message) {
      const popup = document.getElementById('custom-popup');
      const popupMessage = document.getElementById('popup-message');
      const popupButtons = document.getElementById('popup-buttons');

      popupMessage.innerText = message;
      popup.style.display = 'flex';
      popupButtons.innerHTML = '';
      popupButtons.style.justifyContent = 'center';

      const okButton = document.createElement('button');
      okButton.innerText = 'OK';
      okButton.id = 'popup-ok';
      okButton.onclick = async () => {
        await this.$refs.popup.fecharPopup();
        this.$router.push('/admin/imoveis');
      };

      popupButtons.appendChild(okButton);
    },
    async enviarImagens(imovelId) {
      const formData = new FormData();

      this.imagensTemporarias.forEach((img) => {
        if (img.isNew) {
          formData.append('imagem', img.file, img.file.name);
        }
      });

      formData.append('todasImagens', JSON.stringify(this.imagensTemporarias.map(img => ({
        src: img.src,
        filename: img.filename,
        url: img.url || `${process.env.VUE_APP_STORAGE_IMAGENS_URL}/${process.env.VUE_APP_IMOBILIARIA}/imagens/${imovelId}/${img.filename}`,
        ordem: img.ordem
      }))));

      const url = `${process.env.VUE_APP_BACKEND_URL}/imoveis/imagem/${imovelId}`;

      try {
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }

        console.log('Imagens enviadas com sucesso');
        this.imagensSalvas = [...this.imagensTemporarias];
      } catch (error) {
        this.$refs.popup.showAlert('Erro ao enviar imagens ' + error.message)
        throw error;
      }
    },
    async handleFetchWithToken(url, options = {}) {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token de autenticação não encontrado.');
      }

      const headers = {
        'Authorization': `Bearer ${token}`,
        ...options.headers,
      };

      const response = await api.get(url, { ...options, headers });

      if (response.status === 401) {
        const errorData = await response.data;
        if (errorData === 'Token expired.') {
          emitter.emit('token-expired');
          localStorage.removeItem('token');
          throw new Error('Token expired.');
        }
      }

      return response;
    },
    abrirModalUpload() {
      this.imagensSalvasBackup = JSON.parse(JSON.stringify(this.imagensTemporarias));
      this.mostrarModalUpload = true;
      this.$nextTick(() => {
        if (this.$refs.preview && this.$refs.uploadPlaceholder) {
          this.carregarImagensTemporarias();
          this.inicializarSortable();
        }
      });
    },
    cancelarModalUpload() {
      console.log('Cancelando modal de upload');
      this.imagensTemporarias = JSON.parse(JSON.stringify(this.imagensSalvasBackup));
      this.mostrarModalUpload = false;
    },
    carregarImagensSelecionadas(event) {
      const input = event.target;
      const preview = this.$refs.preview;
      const uploadPlaceholder = this.$refs.uploadPlaceholder;
      const currentImages = preview.querySelectorAll('.img-container').length;
      const filesToAdd = input.files.length;

      if (currentImages + filesToAdd > this.MAX_IMAGES) {
        this.$refs.popup.showAlert(`Você só pode adicionar até ${this.MAX_IMAGES} imagens.`);
        input.value = '';
        return;
      }

      for (const file of input.files) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imgContainer = document.createElement('div');
          imgContainer.classList.add('img-container');
          const img = document.createElement('img');
          img.src = event.target.result;
          img.file = file;
          imgContainer.appendChild(img);

          const removeButton = document.createElement('button');
          removeButton.classList.add('remove-image');
          removeButton.onclick = () => {
            imgContainer.remove();
            const index = this.imagensTemporarias.findIndex((img) => img.src === event.target.result);
            if (index !== -1) {
              this.imagensTemporarias.splice(index, 1);
            }
            this.verificarLimiteImagens();
          };
          imgContainer.appendChild(removeButton);

          preview.insertBefore(imgContainer, uploadPlaceholder);
          this.imagensTemporarias.push({ src: event.target.result, file, isNew: true, ordem: this.imagensTemporarias.length });
        };
        reader.readAsDataURL(file);
      }

      input.value = '';

      this.inicializarSortable();
      this.verificarLimiteImagens();
    },
    carregarImagensTemporarias() {
      if (!this.$refs.preview || !this.$refs.uploadPlaceholder) {
        console.error('Elementos necessários não encontrados para carregar as imagens salvas.');
        return;
      }

      const preview = this.$refs.preview;
      const uploadPlaceholder = this.$refs.uploadPlaceholder;

      while (preview.firstChild) {
        preview.removeChild(preview.firstChild);
      }

      preview.appendChild(uploadPlaceholder);
      this.imagensTemporarias.forEach((imagem) => {
        const imgContainer = document.createElement('div');
        imgContainer.classList.add('img-container');
        const img = document.createElement('img');
        img.src = imagem.src;
        img.file = imagem.file;
        imgContainer.appendChild(img);

        const removeButton = document.createElement('button');
        removeButton.classList.add('remove-image');
        removeButton.onclick = () => {
          imgContainer.remove();
          const index = this.imagensTemporarias.indexOf(imagem);
          if (index > -1) {
            this.imagensTemporarias.splice(index, 1);
          }
          this.verificarLimiteImagens();
        };
        imgContainer.appendChild(removeButton);

        preview.insertBefore(imgContainer, uploadPlaceholder);
      });

      this.verificarLimiteImagens();
    },
    inicializarSortable() {
      if (this.sortableInstance) {
        this.sortableInstance.destroy();
      }
      this.sortableInstance = new Sortable(this.$refs.preview, {
        animation: 150,
        ghostClass: 'sortable-ghost',
        filter: '#uploadPlaceholder',
        onEnd: (evt) => {
          const uploadPlaceholder = this.$refs.uploadPlaceholder;
          if (uploadPlaceholder.parentNode) {
            uploadPlaceholder.parentNode.appendChild(uploadPlaceholder);
          }

          const imgContainers = this.$refs.preview.querySelectorAll('.img-container');
          imgContainers.forEach((container, index) => {
            const img = container.querySelector('img');
            if (img) {
              const tempImg = this.imagensTemporarias.find(image => image.src === img.src);
              if (tempImg) {
                tempImg.ordem = index;
              }
            }
          });
        },
      });
    },
    saveAndCloseModal() {
      const preview = this.$refs.preview;
      const imgContainers = preview.querySelectorAll('.img-container');
      this.imagensTemporarias = [];

      imgContainers.forEach((container, index) => {
        const img = container.querySelector('img');
        if (img) {
          this.imagensTemporarias.push({
            src: img.src,
            file: img.file,
            ordem: index,
            isNew: img.file ? true : false,
            filename: img.filename || (img.src.split('/').pop())
          });
        }
      });

      this.mostrarModalUpload = false;
      console.log('Imagens salvas:', this.imagensTemporarias);
    },
    verificarLimiteImagens() {
      const preview = this.$refs.preview;
      const uploadPlaceholder = this.$refs.uploadPlaceholder;
      const currentImages = this.imagensTemporarias.length;

      if (currentImages >= this.MAX_IMAGES) {
        uploadPlaceholder.style.display = 'none';
      } else {
        uploadPlaceholder.style.display = 'flex';
      }
    },
    async carregarDadosImovel(id) {
      try {
        const response = await this.handleFetchWithToken(`${process.env.VUE_APP_BACKEND_URL}/imoveis/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const imovel = await response.data;
        this.preencherFormulario(imovel);
        await this.carregarImagensExistentes(imovel.imagens);
        this.saveInitialFormState();
      } catch (error) {
        console.error('Erro ao carregar dados do imóvel:', error);
        await this.$refs.popup.showAlert('Erro ao carregar dados do imóvel: ' + error.response.data);
      }
    },
    carregarImagensExistentes(imagens) {
      imagens.sort((a, b) => a.ordem - b.ordem);
      this.imagensSalvas = imagens.map((imagem) => {
        return {
          src: imagem.url,
          filename: imagem.filename,
          ordem: imagem.ordem,
          isNew: false
        };
      });
      this.imagensTemporarias = [...this.imagensSalvas];
      this.carregarImagensTemporarias();
    },
    preencherFormulario(imovel) {
      const [latitude, longitude] = imovel.idGeoLocation.split(',').map(coord => coord.trim());

      this.form = {
        categoria: imovel.categoria,
        comercio: Array.isArray(imovel.comercio) ? imovel.comercio : (imovel.comercio ? imovel.comercio.split(',') : []),
        proprietario: imovel.id_proprietario ? imovel.id_proprietario : '',
        areaTotal: this.formatarArea(imovel.areaTotal) || '',
        areaConstruida: this.formatarArea(imovel.areaConstruida) || '',
        quartos: imovel.quartos || '',
        banheiros: imovel.banheiros || '',
        garagem: imovel.garagem || '',
        cep: this.formatarCEP(imovel.cep) || '',
        rua: imovel.rua,
        numero: imovel.numero ? imovel.numero : '',
        bairro: imovel.bairro,
        cidade: imovel.cidade,
        estado: this.formatarEstado(imovel.estado),
        latitude: latitude || '',
        longitude: longitude || '',
        valorVenda: imovel.valorVenda ? this.formatarValor(imovel.valorVenda) : '',
        valorAluguel: imovel.valorAluguel ? this.formatarValor(imovel.valorAluguel, true) : '',
        consultarValorVenda: imovel.consultarValorVenda,
        consultarValorAluguel: imovel.consultarValorAluguel,
        iptu: imovel.iptu ? this.formatarValor(imovel.iptu) : '',
        condominio: imovel.condominio ? this.formatarValor(imovel.condominio) : '',
        ocultarIPTU: imovel.ocultarIPTU,
        ocultarCondominio: imovel.ocultarCondominio,
        parcelamento: imovel.parcelamento === true ? 'true' : 'false',
        financiamento: imovel.financiamento === true ? 'true' : 'false',
        negociacao: imovel.negociacao === true ? 'true' : 'false',
        descricaoImovel: imovel.descricaoImovel,
        detalhesImovel: imovel.detalhesImovel ? imovel.detalhesImovel.join(', ') : '',
        proximidades: imovel.proximidades ? imovel.proximidades.join(', ') : ''
      };
    },
    async inserirEnderecoBuscado(cep) {
      cep = cep.replace(/\D/g, '');
      if (cep.length === 8) {
        const endereco = await this.buscarEndereco(cep);
        this.form.rua = endereco.logradouro;
        this.form.bairro = endereco.bairro;
        this.form.cidade = endereco.localidade;
        this.form.estado = endereco.uf;
      }
    },
    async inserirEnderecoBuscadoCliente(cep) {
      cep = cep.replace(/\D/g, '');
      if (cep.length === 8) {
        const endereco = await this.buscarEndereco(cep);
        this.form_cliente.endereco.rua = endereco.logradouro;
        this.form_cliente.endereco.bairro = endereco.bairro;
        this.form_cliente.endereco.cidade = endereco.localidade;
        this.form_cliente.endereco.estado = endereco.uf;
      }
    },
    async carregarClientes(search = '') {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/clientes?query=${search}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (this.form.proprietario) {
          const selected = data.clientes.find(cliente => cliente._id === this.form.proprietario);
          if (selected) {
            data.clientes = [selected, ...data.clientes.filter(cliente => cliente._id !== this.form.proprietario)];
          }
        }

        this.opcoesProprietarios = data.clientes;

      } catch (error) {
        console.error(`Erro ao buscar clientes:`, error);
      }
    },
    async toggleDisplayOpcoesCategoria() {
      if (this.isEdit) {
        try {
          const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/imoveis/${this.$route.params.id}/verificar-chaves`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const data = await response.json();

          if (data.temChaves) {
            await this.$refs.popup.showAlertConfirm('Não é possível alterar a categoria deste imóvel. \n Contém uma chave retirada.');
            return;
          }
        } catch (error) {
          console.error('Erro ao verificar chaves associadas ao imóvel:', error);
          await this.$refs.popup.showAlert('Erro ao verificar chaves associadas ao imóvel.');
          return;
        }
      }
      this.displayOpcoesCategoria = !this.displayOpcoesCategoria;
      if (this.displayOpcoesCategoria) {
        this.categoriasFiltradas = this.categorias;
      }
    },
    filtrarCategorias(query) {
      this.categoriasFiltradas = this.categorias.filter(categoria =>
        categoria.toLowerCase().includes(query.toLowerCase())
      );
    },
    removeCategoria() {
      this.form.categoria = '';
    },
    toggleDisplayOpcoesProprietario() {
      this.displayOpcoesProprietarios = !this.displayOpcoesProprietarios;
    },
    removeProprietario() {
      this.form.proprietario = '';
    },
    closeDropDowns() {
      document.addEventListener('click', (event) => {
        const isClickDropCliente = event.target.id === 'drop-proprietario';
        const isClickToggleCliente = event.target.id === 'select-proprietario';
        const isClickTextToggleCliente = event.target.id === 'selected-proprietario';
        const isClickIconToggleCliente = event.target.id === 'icon-expanded-proprietario';
        const isClickFilterProprietario = event.target.classList.contains('filter-proprietario');

        if (!isClickDropCliente && !isClickToggleCliente && !isClickTextToggleCliente && !isClickIconToggleCliente && !isClickFilterProprietario) {
          this.displayOpcoesProprietarios = false;
        }

        const isClickDropEstadoCivil = event.target.id === 'drop-estadoCivil';
        const isClickToggleEstadoCivil = event.target.id === 'select-estadoCivil';
        const isClickTextToggleEstadoCivil = event.target.id === 'selected-estadoCivil';
        const isClickIconToggleEstadoCivil = event.target.classList.contains('icon-expanded');

        if (!isClickDropEstadoCivil && !isClickToggleEstadoCivil && !isClickTextToggleEstadoCivil && !isClickIconToggleEstadoCivil) {
          this.displayOpcoesEstadoCivil = false;
        }

        const isClickDropCategoria = event.target.id === 'drop-categoria';
        const isClickToggleCategoria = event.target.id === 'select-categoria';
        const isClickTextToggleCategoria = event.target.id === 'selected-categoria';
        const isClickIconToggleCategoria = event.target.classList.contains('icon-expanded');

        if (!isClickDropCategoria && !isClickToggleCategoria && !isClickTextToggleCategoria && !isClickIconToggleCategoria) {
          this.displayOpcoesCategoria = false;
        }
      });
    },
    openModalAddCliente() {
      this.displayModalAddCliente = true
    },
    closeModalAddCliente() {
      this.displayModalAddCliente = false
    },
    toggleDisplayOpcoesEstadoCivil() {
      this.displayOpcoesEstadoCivil = !this.displayOpcoesEstadoCivil;
    },
    async cadastrarCliente() {
      this.isLoading = true
      this.limparRespotasCliente();

      const payload = { ...this.form_cliente };
      payload.documento = payload.documento.replace(/\D/g, '');
      payload.endereco.cep = payload.endereco.cep ? payload.endereco.cep.replace(/\D/g, '') : null;
      payload.telefoneCelular = payload.telefoneCelular.replace(/\D/g, '');

      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/clientes`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }

        const data = await response.json();

        await this.carregarClientes();
        this.form.proprietario = data._id;
        this.closeModalAddCliente();

        if (this.documentosTemporarios.length > 0) {
          await this.enviarDocumentos(data._id);
        }

        this.$refs.popup.showAlert(`Cliente cadastrado com sucesso!`);

      } catch (error) {
        this.mostrarErroCliente(error.message);
      } finally {
        this.isLoading = false
      }
    },

    limparRespotasCliente() {
      this.errors_cliente = {
        nome: '',
        sobrenome: '',
        documento: '',
        email: ''
      }
    },
    mostrarErroCliente(mensagem) {
      mensagem = mensagem.replace(/"/g, '')
      if (mensagem.includes('O nome deve conter apenas letras')) {
        this.errors_cliente.nome = mensagem;
      } else if (mensagem.includes('O sobrenome deve conter apenas letras')) {
        this.errors_cliente.sobrenome = mensagem;
      } else if (mensagem.includes('documento') || mensagem.includes('Documento')) {
        this.errors_cliente.documento = mensagem;
      } else if (mensagem.includes('E-mail')) {
        this.errors_cliente.email = mensagem;
      } else {
        console.error('Erro ao salvar cliente:', mensagem);
        this.$refs.popup.showAlert('Erro ao salvar cliente:', mensagem)
      }
    },

    carregarDocumentosTemporarios() {
      if (!this.$refs.preview_documento || !this.$refs.uploadPlaceholder_documento) {
        console.error('Elementos necessários não encontrados para carregar os documentos salvos.');
        return;
      }

      const preview = this.$refs.preview_documento;
      const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;

      while (preview.firstChild) {
        preview.removeChild(preview.firstChild);
      }

      preview.appendChild(uploadPlaceholder);
      this.documentosTemporarios.forEach((imagem) => {
        const fileContainer = document.createElement('div');
        fileContainer.classList.add('file-container');
        const file = document.createElement('img');
        file.src = imagem.src;
        file.file = imagem.file;
        fileContainer.appendChild(file);

        const removeButton = document.createElement('button');
        removeButton.classList.add('remove-file');
        removeButton.onclick = () => {
          fileContainer.remove();
          const index = this.documentosTemporarios.indexOf(imagem);
          if (index > -1) {
            this.documentosTemporarios.splice(index, 1);
          }
          this.verificarLimiteDocumentos();
        };
        fileContainer.appendChild(removeButton);

        preview.insertBefore(fileContainer, uploadPlaceholder);
      });

      this.verificarLimiteDocumentos();
    },

    carregarDocumentosSelecionados(event) {
      const input = event.target;
      const preview = this.$refs.preview_documento;
      const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
      const currentFiles = preview.querySelectorAll('.file-container').length;
      const filesToAdd = input.files.length;

      if (currentFiles + filesToAdd > this.MAX_FILES) {
        this.$refs.popup.showAlert(`Você só pode adicionar até ${this.MAX_FILES} documentos.`);
        input.value = '';
        return;
      }

      for (const file of input.files) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContainer = document.createElement('div');
          fileContainer.classList.add('file-container');
          const img = document.createElement('img');
          img.src = event.target.result;
          img.file = file;
          fileContainer.appendChild(img);

          const removeButton = document.createElement('button');
          removeButton.classList.add('remove-file');
          removeButton.onclick = () => {
            fileContainer.remove();
            const index = this.documentosTemporarios.findIndex((img) => img.src === event.target.result);
            if (index !== -1) {
              this.documentosTemporarios.splice(index, 1);
            }
            this.verificarLimiteDocumentos();
          };
          fileContainer.appendChild(removeButton);

          preview.insertBefore(fileContainer, uploadPlaceholder);
          this.documentosTemporarios.push({ src: event.target.result, file, isNew: true, ordem: this.documentosTemporarios.length });
        };
        reader.readAsDataURL(file);
      }

      input.value = '';

      this.inicializarSortableDocumento();
      this.verificarLimiteDocumentos();
    },
    abrirModalUploadDocumentos() {
      this.documentosSalvosBackup = JSON.parse(JSON.stringify(this.documentosTemporarios));
      this.mostrarModalUploadDocumento = true;
      this.$nextTick(() => {
        if (this.$refs.preview_documento && this.$refs.uploadPlaceholder_documento) {
          this.carregarDocumentosTemporarios();
          this.inicializarSortableDocumento();
        }
      });
    },

    cancelarModalUploadDocumentos() {
      this.documentosTemporarios = JSON.parse(JSON.stringify(this.documentosSalvosBackup));
      this.mostrarModalUploadDocumento = false;
    },
    saveAndCloseModalDocumentos() {
      const preview = this.$refs.preview_documento;
      const fileContainers = preview.querySelectorAll('.file-container');
      this.documentosTemporarios = [];

      fileContainers.forEach((container, index) => {
        const img = container.querySelector('img');
        if (img) {
          this.documentosTemporarios.push({
            src: img.src,
            file: img.file,
            ordem: index,
            isNew: img.file ? true : false,
            filename: img.filename || (img.src.split('/').pop())
          });
        }
      });

      this.mostrarModalUploadDocumento = false;
    },
    verificarLimiteDocumentos() {
      const preview = this.$refs.preview_documento;
      const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
      const currentFiles = preview.querySelectorAll('.file-container').length;

      if (currentFiles >= this.MAX_FILES) {
        uploadPlaceholder.style.display = 'none';
      } else {
        uploadPlaceholder.style.display = 'flex';
      }
    },
    inicializarSortableDocumento() {
      if (this.sortableInstance) {
        this.sortableInstance.destroy();
      }
      this.sortableInstance = new Sortable(this.$refs.preview_documento, {
        animation: 150,
        ghostClass: 'sortable-ghost',
        filter: '#uploadPlaceholder',
        onEnd: (evt) => {
          const uploadPlaceholder = this.$refs.uploadPlaceholder_documento;
          if (uploadPlaceholder.parentNode) {
            uploadPlaceholder.parentNode.appendChild(uploadPlaceholder);
          }

          const fileContainers = this.$refs.preview_documento.querySelectorAll('.file-container');
          fileContainers.forEach((container, index) => {
            const img = container.querySelector('img');
            if (img) {
              const tempImg = this.documentosTemporarios.find(image => image.src === img.src);
              if (tempImg) {
                tempImg.ordem = index;
              }
            }
          });
        },
      });
    },
    async enviarDocumentos(clienteId) {
      const formData = new FormData();

      this.documentosTemporarios.forEach((img) => {
        if (img.isNew) {
          formData.append('imagem', img.file, img.file.name);
        }
      });

      formData.append('todasImagens', JSON.stringify(this.documentosTemporarios.map(img => ({
        src: img.src,
        filename: img.filename,
        url: img.url || `${process.env.VUE_APP_STORAGE_IMAGENS_URL}/${process.env.VUE_APP_IMOBILIARIA}/clientes/documentos/${clienteId}/${img.filename}`,
        ordem: img.ordem
      }))));

      const url = `${process.env.VUE_APP_BACKEND_URL}/clientes/imagem/${clienteId}`;

      try {
        const response = await fetch(url, {
          method: 'PUT',
          body: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }

        this.documentosSalvos = [...this.documentosTemporarios];
      } catch (error) {
        await this.$refs.popup.showAlert('Erro ao enviar documentos: ' + error.message)
        throw error;
      }
    },
    carregarDocumentosExistentes(documentos) {
      documentos.sort((a, b) => a.ordem - b.ordem);
      this.documentosSalvos = documentos.map((imagem) => {
        return {
          src: imagem.url,
          filename: imagem.filename,
          ordem: imagem.ordem,
          isNew: false
        };
      });
      this.documentosTemporarios = [...this.documentosSalvos];
      this.carregarDocumentosTemporarios();
    },
    saveInitialFormStateDocumento() {
      this.initialFormState = JSON.parse(JSON.stringify(this.form_cliente));
      this.imagensTemporarias = JSON.parse(JSON.stringify(this.documentosSalvos));
    },
    hasFormChangedDocumento() {
      const formChanged = JSON.stringify(this.form) !== JSON.stringify(this.initialFormStateDocumento);
      const imagesChanged = !this.compareArrays(this.documentosTemporarios, this.documentosSalvos);
      return formChanged || imagesChanged;
    },
    removeAllImages() {
      this.imagensTemporarias = [];
      const preview = this.$refs.preview;
      while (preview.firstChild) {
        preview.removeChild(preview.firstChild);
      }
      preview.appendChild(this.$refs.uploadPlaceholder);
      this.verificarLimiteImagens();
    }
  },
  async mounted() {
    await this.carregarClientes();
    this.closeDropDowns();
  },
  watch: {
    displayOpcoesCategoria(newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.$refs.filterCategoriaInput.focus();
        });
      }
    },
    displayOpcoesProprietarios(newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.$refs.filterProprietarioInput.focus();
        });
      }
    }
  },
  components: {
    Popup,
    Loading
  },
};
</script>

<style scoped>
@import '@/01-admin/assets/css/cadastro-imoveis.css';
</style>